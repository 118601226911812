import React from "react";
import ProductCard from "../../productComponent/productCard/productCardComponent";

import "./productList.css";

export default function ProductListComponent(props) {
    return (
        <div>
            <h2 className="landing-category">{props.category}</h2>
            {props.items.length > 0 ? (
                <ul className={"product-list"}>
                    {props.items.map((item, index) => (
                        <li
                            key={index}
                            data-key={index}
                            className={"product-item"}>
                            <ProductCard item={item} />
                        </li>
                    ))}
                </ul>
            ) : (
                <p>Nothing here, keep browsing to see items appear here!</p>
            )}
        </div>
    );
}
