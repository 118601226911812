import React from "react";
import { Link } from "react-router-dom";

import "./productCard.css";

export default function ProductCard(props) {
    return (
        <div className="product-card">
            <Link to={`/products?id=${props.item.item_id}`}>
                <img
                    src={props.item.image}
                    alt={"Product preview"}
                    height="120px"
                    className={"product-image"}
                />
                <hr />
                <div className="product-information-container">
                    <div className="product-information">
                        <h2 className="product-title">{props.item.name}</h2>
                        <p className="product-price-header">Best offer:</p>
                        <p className="product-price product-grey-color">
                            ¥{props.item.price}
                        </p>
                    </div>
                    <p className="product-sold product-grey-color">
                        Sold {props.item.sold} times
                    </p>
                </div>
            </Link>
        </div>
    );
}
