import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import ProductListComponent from "./productList";
import { getItemLocalStorage } from "../../utils/localStorageHandler";

import "./loggedIn.css";

import ProductContext from "../../context/productContext";

export default function LoggedIn(props) {
    const productContext = useContext(ProductContext);
    const [getProducts, setProducts] = useState({
        mostSold: [],
        lastSeen: [],
    });

    useEffect(() => {
        const allItems = productContext.products;
        const mostSold = productContext.products.slice(0, 4); // Sorted in the backend
        let lastSeen = getItemLocalStorage("lastSeen") || [];
        lastSeen = lastSeen.map((curValue, index) => {
            let newValue = curValue;
            for (let i = 0; i < allItems.length; i++) {
                if (allItems[i].item_id === curValue) {
                    newValue = allItems[i];
                }
            }
            if (newValue === curValue) {
            }
            return newValue;
        });
        setProducts({
            mostSold: mostSold,
            lastSeen: lastSeen,
        });
    }, [productContext.products]);

    return (
        <div className="item-landingpage">
            <h1 className={"landingpage-title"}>Tatsu market place</h1>
            <ProductListComponent
                category={"Most sold"}
                items={getProducts.mostSold}
            />
            <br />
            <ProductListComponent
                category={"Last seen"}
                items={getProducts.lastSeen}
            />
            <br />
            <Link to={"/search"} className={"all-items-button"}>
                Check out all items
            </Link>
        </div>
    );
}
