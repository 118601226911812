import React from "react";

import ProfilePageCategory from "./components/profilePageCategory";
import CopyField from "../components/copyField";

import { getItemLocalStorage } from "../utils/localStorageHandler";
import { ErrorHandler } from "../utils/errorHandler";

import "./profilePage.css";

export default function UserProfile() {
    const user = getItemLocalStorage("user");
    if (!user) {
        ErrorHandler(
            "user not logged in",
            "Something went wrong please try to login again!"
        );
        return;
    }

    return (
        <div className="profile-container">
            <img
                src={`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`}
                alt={"user profile"}
                className={"profile-picture"}
            />
            <h1>{user.name}</h1>
            <ProfilePageCategory title={"API Key"}>
                <CopyField
                    text={localStorage.getItem("token")}
                    type={"api key"}
                    image={
                        "https://img.icons8.com/material-outlined/24/000000/copy-2.png"
                    }
                />
            </ProfilePageCategory>
        </div>
    );
}
