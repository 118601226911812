import React from "react";

export default function LoginButton(props) {
    let url = process.env.REACT_APP_DISCORD_LOGIN;
    return (
        <a id="login" href={url}>
            {props.text || "Login"}
        </a>
    );
}
