import React from "react";
import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.min.css";

export function ErrorHandler(error, message) {
    ErrorNotification(message);
    console.error(error);
    // TODO: add proper error handling
}

export function ErrorNotification(message) {
    const options = { hideProgressBar: true, toastId: "error-message" };
    const notify = () => toast.error(message, options);
    notify();
    return <ToastContainer autoclose={2000} />;
}
