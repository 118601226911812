import React from "react";

const ProductContext = React.createContext({
    products: [],
    loggedIn: false,
    token: "",
    logIn: () => {}
});

export const ProductProvider = ProductContext.Provider;

export default ProductContext;
