import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import "./profileLink.css";

export default function ProfilePageLink() {
    return (
        <div className={"profile-page-link"}>
            <NavItem>
                <ProfileDropdownMenu />
            </NavItem>
        </div>
    );
}

function NavItem(props) {
    const [open, setOpen] = useState(false);
    const history = useHistory();

    useEffect(() => {
        return history.listen((location) => {
            setOpen(false);
        });
    }, [history]);

    return (
        <div className="nav-item">
            <div className="profile-link-button" onClick={() => setOpen(!open)}>
                Profile
            </div>

            {open && props.children}
        </div>
    );
}

function ProfileDropdownMenu() {
    const [menuHeight, setMenuHeight] = useState(50);
    const dropdownRef = useRef(null);

    useEffect(() => {
        setMenuHeight(120);
    }, []);

    function calcHeight(el) {
        const height = el.offsetHeight;
        setMenuHeight(height);
    }

    function ProfileDropdownItem(props) {
        return (
            <Link to={`/profile/${props.page}`} className={"menu-item"}>
                <span className={"icon"}>{props.icon}</span>
                {props.children}
            </Link>
        );
    }

    return (
        <div
            className="profile-dropdown"
            style={{ height: menuHeight }}
            ref={dropdownRef}>
            <CSSTransition
                in={true}
                timeout={500}
                classNames={"profile-menu"}
                unmountOnExit
                onEnter={calcHeight}>
                <div className="profile-dropdown-menu">
                    <ProfileDropdownItem page={""}>Profile</ProfileDropdownItem>
                    <ProfileDropdownItem page={"notifications"}>
                        Notifications
                    </ProfileDropdownItem>
                </div>
            </CSSTransition>
        </div>
    );
}
