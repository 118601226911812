import React, { Component } from "react";
import PropTypes from "prop-types";

import "./autocomplete.css";
import ProductWide from "../../productComponent/productWide/productWide";

export class Autocomplete extends Component {
    static propTypes = {
        suggestions: PropTypes.instanceOf(Array),
    };
    static defaultProperty = {
        suggestions: [],
    };
    constructor(props) {
        super(props);
        this.state = {
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: "",
        };
    }

    onChange = (e) => {
        const { suggestions } = this.props;
        const userInput = e.currentTarget.value;
        const filteredSuggestions = suggestions.filter(
            (suggestion) =>
                suggestion.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );

        this.setState({
            activeSuggestion: 0,
            filteredSuggestions,
            showSuggestions: true,
            userInput: e.currentTarget.value,
        });
    };

    render() {
        const {
            onChange,
            onClick,
            state: { filteredSuggestions, showSuggestions, userInput },
        } = this;
        let suggestionsListComponent;
        if (showSuggestions && userInput) {
            if (filteredSuggestions.length) {
                suggestionsListComponent = (
                    <ul className="suggestions">
                        {filteredSuggestions.map((suggestion, index) => {
                            return (
                                <li key={index} onClick={onClick} data-key={index}>
                                    <ProductWide product={suggestion} />
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                suggestionsListComponent = (
                    <div className="no-suggestions">
                        <em>No suggestions</em>
                    </div>
                );
            }
        }

        return (
            <div className="autoSuggestionsContainer">
                <div className="autoSuggestionSearchBox">
                    <span className="material-icons">search</span>
                    <input
                        type="search"
                        onChange={onChange}
                        value={userInput}
                        placeholder={this.props.placeholder}
                    />
                </div>
                {suggestionsListComponent}
            </div>
        );
    }
}

export default Autocomplete;
