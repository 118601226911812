import React from "react";

import "./notificationButton.css";

export default function NotificationButton(props) {
    return (
        <button
            onClick={props.onClick}
            className={`notification-button ${props.button_type}`}>
            {props.children}
        </button>
    );
}
