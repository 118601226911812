import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

import ProductInformation from "./components/productInformation";
import {
    getItemLocalStorage,
    setItemLocalStorage,
} from "../../utils/localStorageHandler";
import CheckoutProduct from "./components/productCheckout";

import "./productPage.css";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function ProductPage(props) {
    let query = useQuery();
    const [item, setItem] = useState({
        item_id: "",
        sold: 0,
        buying_offers: [{ price: null, user_id: null }],
        selling_offers: [{ price: null, user_id: null }],
        image_url: "",
        item_name: "",
        item_description: "",
        item_description_long: "",
        show_checkout: "",
    });

    const [productCheckout, setProductCheckout] = useState({
        show: false,
        type: "",
        product: {},
    });

    const changeCheckoutState = (checkoutType, product) => {
        setProductCheckout({
            show: !productCheckout.show,
            type: checkoutType,
            product: product,
        });
    };

    useEffect(() => {
        const item_id = query.get("id");
        const activeToken = localStorage.getItem("token");
        if (activeToken === "" || activeToken === undefined) {
            return <h1>You need to be logged in to view products</h1>;
        } else {
            let curLastSeen = getItemLocalStorage("lastSeen") || [];
            if (!Array.isArray(curLastSeen)) {
                setItemLocalStorage("lastSeen", [item_id]);
            } else if (!curLastSeen.includes(item_id)) {
                if (curLastSeen.length >= 4) {
                    curLastSeen.shift();
                }
                curLastSeen.push(item_id);
                setItemLocalStorage("lastSeen", curLastSeen);
            }
            axios
                .get(
                    `${process.env.REACT_APP_BASE_API_URL}/product/${item_id}`,
                    {
                        headers: { Authorization: activeToken },
                    }
                )
                .then((res) => {
                    // sort and handle selling offers
                    let selling_offers = [{ price: null, user_id: null }];
                    const response = res.data;
                    if (response.selling_offers) {
                        selling_offers = response.selling_offers.sort(
                            (a, b) => b.price - a.price
                        );
                    }
                    // sort and handle buying offers
                    let buying_offers = [{ price: null, user_id: null }];
                    if (response.buying_offers) {
                        buying_offers = response.buying_offers.sort(
                            (a, b) => a.price - b.price
                        );
                    }
                    setItem({
                        item_id: response.item_id,
                        sold: response.sold,
                        buying_offers: buying_offers,
                        selling_offers: selling_offers,
                        image_url: response.image_url,
                        item_name: response.item_name,
                        item_description: response.description,
                        item_description_long: response.description_long,
                    });
                });
        }
        // eslint-disable-next-line
    }, []);
    if (!productCheckout.show) {
        return (
            <div className="product-container">
                <ProductInformation
                    item={item}
                    changeCheckoutState={(checkoutType, product) =>
                        changeCheckoutState(checkoutType, product)
                    }
                />
            </div>
        );
    } else {
        return (
            <CheckoutProduct
                item={item}
                productCheckout={productCheckout}
                changeCheckoutState={(checkoutType, product) =>
                    changeCheckoutState(checkoutType, product)
                }
            />
        );
    }
}
