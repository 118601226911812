import React from "react";

import "./footerComponent.css";

export default function Footer() {
    return (
        <div className="footer-container">
            <ul>
                <li>
                    <a
                        href="https://github.com/PumPum7"
                        target="_blank"
                        rel={"noreferrer"}
                        className="footer-link">
                        Github
                    </a>
                </li>
                <li>
                    <a
                        href="https://tatsu.gg/"
                        target="_blank"
                        rel={"noreferrer"}
                        className="footer-link">
                        Tatsu
                    </a>
                </li>
            </ul>
        </div>
    );
}
