import React from "react";
import { Link } from "react-router-dom";

import PageComponent from "./components/pages";
import LoginButton from "./components/login";
import "./navbar.css";
import ProfilePageLink from "./components/profileLink";

export default class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
        };
    }

    componentDidMount() {
        const token = localStorage.getItem("token") !== null;
        this.setState({ isLoggedIn: token });
    }

    render() {
        return (
            <nav
                role="navigation"
                aria-label="Navigation bar"
                style={{ marginTop: 20 }}>
                <div className="navbar">
                    <Link to={"/"} className={"navbarLogo"}>
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/icon.png`}
                            alt={"Logo"}
                            typeof="image/png"
                            height="18px"
                        />
                        Home
                    </Link>
                    <PageComponent link={"/search"}>Search</PageComponent>
                    {this.state.isLoggedIn ? (
                        <ProfilePageLink />
                    ) : (
                        <LoginButton />
                    )}
                </div>
            </nav>
        );
    }
}
