import React from "react";

import "./notification.css";

export default function UserNotification(props) {
    return (
        <div className="user-notification" onClick={props.onClick}>
            <img
                src={props.item.image}
                alt={props.item.name}
                height="120px"
                className={"product-image"}
            />
            <hr style={{ width: "100%" }} />
            <div className="notification-information-container">
                <div className="notification-information">
                    <h2 className="notification-product-title">
                        {props.item.name}
                    </h2>
                </div>
            </div>
        </div>
    );
}
