import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Link } from "react-router-dom";

import ProductImage from "./productImage";
import { getItemLocalStorage } from "../../../utils/localStorageHandler";
import { postNotification } from "../../../utils/handleNotifications";
import { ErrorHandler } from "../../../utils/errorHandler";

import "./productCheckout.css";

export default function CheckoutProduct(props) {
    const checkoutType =
        props.productCheckout.type.toString().charAt(0).toUpperCase() +
        props.productCheckout.type.slice(1);
    const productOffer =
        checkoutType === "Buy"
            ? props.item.buying_offers[0]
            : props.item.selling_offers[0];
    const [getCheckoutState, setCheckoutState] = useState({
        active_method: "direct",
        finished_process: false,
    });

    function finishProcess() {
        setCheckoutState({
            finished_process: true,
            active_method: getCheckoutState.active_method,
        });
    }

    const changeCheckoutState = (method) => {
        setCheckoutState({ active_method: method, finished_process: false });
    };
    if (!getCheckoutState.finished_process) {
        return (
            <div className="product-container">
                <span className={"product-page"}>
                    <div className={"product-checkout-left"}>
                        <button
                            onClick={() =>
                                props.changeCheckoutState("", props.item)
                            }>
                            🡰 Go back
                        </button>
                        <ProductImage src={props.item.image_url} />
                    </div>
                    <div className="product-checkout-right">
                        <div className={"checkout-method-selector"}>
                            <div
                                className={`checkout-method ${
                                    getCheckoutState.active_method === "direct"
                                        ? "active"
                                        : ""
                                }`}
                                onClick={() => changeCheckoutState("direct")}>
                                {checkoutType} now
                            </div>
                            <div
                                className={`checkout-method ${
                                    getCheckoutState.active_method === "offer"
                                        ? "active"
                                        : ""
                                }`}
                                onClick={() => changeCheckoutState("offer")}>
                                Place offer
                            </div>
                        </div>
                        {getCheckoutState.active_method === "direct" ? (
                            <CheckoutDirect
                                checkout_type={checkoutType}
                                offer={productOffer}
                                item={props.item}
                                finish_process={() => finishProcess()}
                            />
                        ) : (
                            <CheckoutAddOffer
                                checkout_type={checkoutType}
                                offer={productOffer}
                                item={props.item}
                                finish_process={() => finishProcess()}
                            />
                        )}
                    </div>
                </span>
            </div>
        );
    } else {
        return (
            <CheckoutFinished
                item={props.item}
                checkout_method={getCheckoutState.active_method}
                checkout_type={checkoutType}
                product_offer={productOffer}
            />
        );
    }
}

function CheckoutFinished(props) {
    return (
        <div className={"product-container checkout-container"}>
            {props.checkout_method === "direct" ? (
                <p className={"checkout-text"}>
                    Successfully{" "}
                    {props.checkout_type === "Buy" ? "purchased" : "sold"}{" "}
                    <strong>{props.item.item_name}</strong>{" "}
                    {props.checkout_type === "Buy" ? "from" : "to"}{" "}
                    {props.product_offer.user_id} (
                    {props.product_offer.user_name}) for{" "}
                    {props.product_offer.price}¥.
                    <br />
                    We will send a notification to them to notify them about the
                    trade.
                </p>
            ) : (
                <p className={"checkout-text"}>
                    Successfully added the{" "}
                    {props.checkout_type.toString().toLowerCase()}ing offer for
                    the item <strong>{props.item.item_name}</strong>.<br />
                    We will notify you once someone accepts the offer.
                </p>
            )}

            <Link to={"/"} className={"finish-checkout"}>
                Return to the homepage
            </Link>
        </div>
    );
}

function CheckoutDirect(props) {
    const { handleSubmit } = useForm();

    async function onSubmit() {
        const user = getItemLocalStorage("user");
        const activeToken = localStorage.getItem("token");
        if (user === null || activeToken === null) {
            ErrorHandler(
                "user not logged in",
                "Something went wrong please try to log out and then in again!"
            );
            return;
        }
        const result = await postNotification(activeToken, user.id, {
            price: parseInt(props.offer.price),
            user_id: user.id,
            item_id: props.item.item_id,
        });
        console.log(result);
        if (result.status === 200 && result) {
            props.finish_process();
        } else {
            ErrorHandler(
                result,
                `Failed to buy the item ${props.item.item_name} from user ${props.offer.user_id}`
            );
        }
    }

    const offerPerson = props.checkout_type === "Buy" ? "Seller" : "Buyer";

    return (
        <div className="checkout-container">
            <form onSubmit={handleSubmit(onSubmit)} className={"offer-form"}>
                <p>
                    {props.checkout_type} {props.item.item_name} now for{" "}
                    {props.offer.price}¥:
                </p>
                <input type={"submit"} value={`${props.checkout_type} item`} />
                <p className={"checkout-note"}>
                    Note: this will notify the {offerPerson}. <br />
                    Both you and them can start the trade.
                </p>
            </form>
        </div>
    );
}

function CheckoutAddOffer(props) {
    const { register, handleSubmit, errors } = useForm();

    const onSubmit = (data) => {
        // TODO: duplicate offers (two from one user)
        const user = getItemLocalStorage("user");
        const activeToken = localStorage.getItem("token");
        if (user === null || activeToken === null) {
            ErrorHandler(
                "user not logged in",
                "Something went wrong please try to log out and then in again!"
            );
            return;
        }
        axios
            .post(
                `${process.env.REACT_APP_BASE_API_URL}/product/${
                    props.checkout_type.toLowerCase() === "buy" ? "sell" : "buy"
                }/${props.item.item_id}`,
                {
                    price: parseInt(data.offer),
                    user_id: user.id,
                    user_name: user.name,
                },
                {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    props.finish_process();
                } else {
                    ErrorHandler(
                        res,
                        "Something went wrong please try again later!"
                    );
                }
            });
    };

    return (
        <div className={"checkout-container"}>
            <form onSubmit={handleSubmit(onSubmit)} className={"offer-form"}>
                <input
                    name={"offer"}
                    ref={register({
                        max:
                            props.checkout_type === "Buy" &&
                            props.offer.price !== 0
                                ? props.offer.price
                                : null,
                        min: 1,
                    })}
                    type={"number"}
                    autoFocus
                    placeholder={"Price"}
                />
                {errors.offer?.type === "max" && (
                    <p className={"offer-error"}>
                        You can directly buy this item for {props.offer.price}¥
                    </p>
                )}
                {errors.offer?.type === "min" && (
                    <p className={"offer-error"}>
                        Offers have to be higher than 1
                    </p>
                )}
                <input type="submit" value={"Submit offer"} />
            </form>
        </div>
    );
}
