import React from "react";

export default function ProfilePageCategory(props) {
    return (
        <div className="profile-page-category">
            <h2>{props.title}</h2>
            {props.children}
        </div>
    );
}
