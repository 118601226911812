import React from "react";

import "./productButton.css";

export default function ProductButton(props) {
    return (
        <button
            className={`button button-${props.buttonType}`}
            onClick={() =>
                props.changeCheckoutState(props.buttonType, props.product)
            }>
            {props.price ? (
                <div className="product-price">¥{props.price || "-"}</div>
            ) : (
                ""
            )}

            {props.price ? <div className="button-divider" /> : ""}
            {props.price
                ? props.children
                : "No " + props.children + "ing offers"}
        </button>
    );
}
