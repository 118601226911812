import axios from "axios";

export function readNotification(token, userID, notification) {
    axios
        .put(
            `${process.env.REACT_APP_BASE_API_URL}/user/notifications/${userID}`,
            {
                user_id: notification.user_id,
                item_id: notification.item_id || notification.item.item_id,
                price: notification.price,
                read: true,
            },
            {
                headers: {
                    Authorization: token,
                },
            }
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.error(error);
            return false;
        });
}

export async function deleteNotification(token, userID, notification) {
    console.log(notification);
    await axios
        .delete(
            `${process.env.REACT_APP_BASE_API_URL}/user/notifications/${userID}`,
            {
                headers: {
                    Authorization: token,
                },
                data: {
                    user_id: notification.user_id,
                    price: notification.price,
                    item_id: notification.item_id,
                    read: notification.read,
                },
            }
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            throw error;
        });
}

export async function postNotification(token, userID, notification) {
    let returnResponse = false;
    await axios
        .post(
            `${process.env.REACT_APP_BASE_API_URL}/user/notifications/${userID}`,
            {
                user_id: notification.user_id,
                price: notification.price,
                item_id: notification.item_id,
                read: notification.read || false,
            },
            {
                headers: {
                    Authorization: token,
                },
            }
        )
        .then((response) => {
            returnResponse = response;
        })
        .catch((error) => {
            console.error(error);
            returnResponse = false;
        });
    return returnResponse;
}
