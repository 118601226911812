import React from "react";

import Autocomplete from "./autocomplete/autocompleteComponent";

import "./search.css";

import ProductContext from "../context/productContext";

export default class SearchComponent extends React.Component {
    static contextType = ProductContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            items: [],
        };
    }

    async componentDidMount() {
        this.setState({ loading: false, items: this.context.products });
    }

    render() {
        return (
            <div>
                {this.state.loading ? (
                    <div>Loading...</div>
                ) : (
                    <SearchItem
                        items={this.state.items}
                        placeholder={this.state.placeholder}
                    />
                )}
            </div>
        );
    }
}

const SearchItem = (props) => {
    return (
        <div className="searchComponent">
            <p className="searchComponentText">
                <strong>Search for Products</strong>
                <br />
                You can only search by name currently.
            </p>
            <Autocomplete
                suggestions={props.items}
                placeholder={props.placeholder}
            />
        </div>
    );
};
