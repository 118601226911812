import React, { useEffect, useState } from "react";
import Select from "react-select";

import ProductButton from "./productButton/productButtons";
import ProductImage from "./productImage";

import "./productInformation.css";

export default function ProductInformation(props) {
    const [itemImage, setItemImage] = useState({
        image: "",
        description: "Preview of the item",
    });

    useEffect(() => {
        setItemImage({
            image: props.item.image_url,
            description: "Preview of the item",
        });
    }, [props]);

    const options = [
        { value: "empty", label: "Only item" },
        { value: "default", label: "Default House" },
    ];

    const changeItemPreview = (previewArgs) => {
        // do logic here to get the actual image
        if (previewArgs.value === "default") {
            setItemImage({
                image:
                    "https://cdn.discordapp.com/attachments/471950596446879744/818745672811151390/room_view.png",
                description: "Preview of the item in the" + previewArgs.label,
            });
        } else {
            setItemImage({
                image: props.item.image_url,
                description: "Preview of the item",
            });
        }
    };

    return (
        <span className="product-page">
            <div className="product-page-left">
                <ProductImage
                    src={itemImage.image}
                    alt={itemImage.description}
                />
            </div>
            <div className="product-page-right">
                <h1 className={"product-header"}>{props.item.item_name}</h1>
                <p className={"product-description-small"}>
                    {props.item.item_description}
                </p>
                <div className={"product-buttons"}>
                    <ProductButton
                        buttonType="buy"
                        product={props.item}
                        price={props.item.buying_offers[0].price}
                        changeCheckoutState={props.changeCheckoutState}>
                        Buy
                    </ProductButton>
                    <ProductButton
                        buttonType="sell"
                        price={props.item.selling_offers[0].price}
                        changeCheckoutState={props.changeCheckoutState}>
                        Sell
                    </ProductButton>
                </div>
                <h2>Preview</h2>
                <Select
                    options={options}
                    onChange={(e) => changeItemPreview(e)}
                    placeholder={"Select a preview"}
                />
                <h2>Description</h2>
                <p className="product-description-long">
                    {props.item.item_description_long}
                </p>
            </div>
        </span>
    );
}
