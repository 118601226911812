import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

import "./copyField.css";

export default function CopyField(props) {
    const [getCopied, setCopied] = useState(false);

    useEffect(() => {
        if (getCopied) {
            alert(`Successfully copied ${props.type}`);
        }
    }, [getCopied, props.type]);

    return (
        <CopyToClipboard text={props.text} onCopy={() => setCopied(true)}>
            <span className={"copy-field"}>
                <p>{props.text}</p>
                <img src={props.image} alt={"copy field"} width={"24"} />
            </span>
        </CopyToClipboard>
    );
}
