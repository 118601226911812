import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

import UserNotification from "./components/notification";
import NotificationButton from "./components/notificationOverlayButton";

import "./profilePage.css";
import "./userNotifications.css";

import ProductContext from "../context/productContext";
import { getItemLocalStorage } from "../utils/localStorageHandler";
import { deleteNotification } from "../utils/handleNotifications";
import { ErrorHandler } from "../utils/errorHandler";

export default function UserNotifications() {
    const [getNotificationHandler, setNotificationHandler] = useState(null);
    const [getNotifications, setNotifications] = useState(null);

    const user = getItemLocalStorage("user");
    const activeToken = localStorage.getItem("token");

    let productContext;
    productContext = useContext(ProductContext);

    function removeNotification(notification) {
        let temporaryArray = [...getNotifications];
        const index = getNotifications.indexOf(notification);
        if (index !== -1) {
            temporaryArray.splice(index, 1);
            setNotifications(temporaryArray);
        }
    }

    function changeNotificationHandler(notification) {
        setNotificationHandler(notification);
    }

    async function closeNotificationHandler(event) {
        if (event.target.className === "notification-overlay") {
            setNotificationHandler(null);
        }
    }

    useEffect(() => {
        if (!user) {
            ErrorHandler(
                "user not logged in",
                "Invalid user please try to re-login."
            );
            return;
        }

        axios
            .get(`${process.env.REACT_APP_BASE_API_URL}/user/${user.id}`, {
                headers: { Authorization: activeToken },
            })
            .then((response) => {
                if (response.status === 200) {
                    // handle no notifications
                    if (!response.data.notifications) {
                        setNotifications(null);
                    } else {
                        let responseData = response.data.notifications;
                        responseData = responseData.map((notification) => {
                            return {
                                user_id: notification.user_id,
                                price: notification.price || 0,
                                item: productContext.products.find(
                                    (product) => {
                                        return (
                                            product.item_id ===
                                            notification.item_id
                                        );
                                    }
                                ) || { item_id: notification.item_id },
                                read: notification.read || false,
                            };
                        });
                        setNotifications(responseData);
                    }
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className="profile-container"
            onClick={(e) => closeNotificationHandler(e)}>
            <h1>Notifications</h1>
            {getNotificationHandler !== null && (
                <NotificationOverlay
                    notification={getNotificationHandler}
                    notification_handler={(notify) =>
                        removeNotification(notify)
                    }
                />
            )}
            <div className="product-notifications">
                {getNotifications ? (
                    getNotifications.map((notification, index) => {
                        return (
                            <li
                                key={`${notification.item.item_id}_${index}`}
                                style={{ listStyle: "none" }}>
                                <UserNotification
                                    item={notification.item}
                                    onClick={() =>
                                        changeNotificationHandler(notification)
                                    }
                                />
                            </li>
                        );
                    })
                ) : (
                    <p>You have no notifications</p>
                )}
            </div>
        </div>
    );
}

function NotificationOverlay(props) {
    const [TradeHandled, setTradeHandled] = useState(false);

    return (
        <div className="notification-overlay">
            <div className="notification-overlay-content-container">
                <div className="notification-overlay-content">
                    <h1>{props.notification.item.name}</h1>
                    <p style={{ textAlign: "center" }}>
                        Information: {props.notification.item.item_id} for{" "}
                        {props.notification.price}¥ to{" "}
                        {props.notification.user_id}
                    </p>
                    {TradeHandled ? (
                        <p>
                            <br />
                            <strong>
                                Successfully {TradeHandled} the trade!
                            </strong>
                        </p>
                    ) : (
                        <TradeNotHandled
                            notification={props.notification}
                            trade_handler={setTradeHandled}
                            notification_handler={(notify) =>
                                props.notification_handler(notify)
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

function TradeNotHandled(props) {
    return (
        <div>
            <h3>Would you like to approve the trade?</h3>
            <p>
                Pressing approve means you have <strong>sold</strong> the item
                to the user.
            </p>
            <p>
                By pressing anywhere outside this card you cancel the approval.
            </p>
            <div className={"notification-buttons"}>
                <NotificationButton
                    onClick={() =>
                        HandleClick(
                            props.notification,
                            true,
                            props.trade_handler,
                            props.notification_handler
                        )
                    }
                    button_type={"approve-trade"}>
                    Approve
                </NotificationButton>
                <NotificationButton
                    onClick={() =>
                        HandleClick(
                            props.notification,
                            false,
                            props.trade_handler,
                            props.notification_handler
                        )
                    }
                    button_type={"reject-trade"}>
                    Reject
                </NotificationButton>
            </div>
        </div>
    );
}

async function HandleClick(
    notification,
    action,
    setTradeHandled,
    notification_handler
) {
    console.log(typeof notification_handler);

    const notification_data = {
        user_id: notification.user_id,
        price: notification.price,
        item_id: notification.item.item_id,
        read: notification.read,
    };
    const user = getItemLocalStorage("user");
    const activeToken = localStorage.getItem("token");
    if (user === null || activeToken === null) {
        ErrorHandler(
            "user not logged in",
            "Something went wrong please try to log out and then in again!"
        );
        return;
    }
    try {
        notification_handler(notification);
        await deleteNotification(activeToken, user.id, notification_data);
    } catch (e) {
        ErrorHandler(
            e,
            `Failed to buy the item ${notification_data.item_id} from user ${notification_data.user_id}`
        );
        return;
    }
    setTradeHandled(action ? "accepted" : "rejected");
}

// TODO: handle notifications in state -> better to handle deletions
