import React from "react";

import LoginButton from "../../navbar/components/login";

import "./newUser.css";

export default function NewUserComponent() {
    return (
        <div className="newUser-container">
            <div className="newUser-left">
                <h1>Tatsu market place</h1>
                <p>
                    A new way to trade your items.
                    <br />
                    All you have to do now is to search for the item.
                    <br />
                    We handle everything else for you!
                </p>
                <br />
                <LoginButton />
            </div>

            <div className="newUser-right">
                <img
                    src={"https://catgirl.host/i/kdp5.png"}
                    alt={"Preview of the service"}
                    width="500"
                />
            </div>
        </div>
    );
}
