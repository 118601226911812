import React from "react";
import axios from "axios";

import { LoginFailed, LoginHandler } from "./loginHandler";
import LoggedIn from "./components/loggedIn";
import NewUserComponent from "./components/newUser";

import { setItemLocalStorage } from "../utils/localStorageHandler";
import ProductContext from "../context/productContext";

export default class LandingPageHandler extends React.Component {
    static contextType = ProductContext;

    constructor(props) {
        super(props);
        this.state = {
            showLoggedIn: false,
            showLoginFailed: false,
        };
    }

    componentDidMount() {
        const fragment = new URLSearchParams(window.location.hash.slice(1));
        const curToken = localStorage.getItem("token");
        if (fragment.has("access_token") && !curToken) {
            const accessToken = fragment.get("access_token");
            let config = {
                headers: {
                    token: accessToken,
                },
            };
            axios
                .post(`${process.env.REACT_APP_BASE_API_URL}/key`, {}, config)
                .then((response) => {
                    localStorage.setItem("token", response.data.key);
                    setItemLocalStorage("user", {
                        id: response.data.user_id,
                        name: response.data.user_name,
                        avatar: response.data.user_avatar,
                    });
                    this.setState({ showLoggedIn: true });
                    this.context.LogIn();
                })
                .catch((error) => {
                    console.error(error);
                    this.setState({
                        showLoginFailed: true,
                        showLoggedIn: false,
                    });
                    localStorage.setItem("token", "");
                    localStorage.setItem("user", "");
                });
        } else if (curToken) {
            this.setState({ showLoggedIn: true });
            this.context.LogIn();
        }
    }

    render() {
        return (
            <div>
                {this.state.showLoggedIn ? <LoginHandler /> : ""}
                {this.state.showLoginFailed ? <LoginFailed /> : ""}
                {this.state.showLoggedIn ? <LoggedIn /> : <NewUserComponent />}
            </div>
        );
    }
}
