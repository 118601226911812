import React from "react";
import { Link } from "react-router-dom";

import "./pages.css";

export default function PageComponent(props) {
    return (
        <Link to={props.link} className={"page"}>
            {props.children}
        </Link>
    );
}
