import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import axios from "axios";

import Navbar from "./navbar/navbar";
import SearchComponent from "./searchComponent/searchComponent";
import ProductPage from "./productComponent/productPage/productPage";
import LandingPageHandler from "./landingPageComponent/landingPageHandler";
import UserProfile from "./profile/UserProfile";
import UserNotifications from "./profile/UserNotifications";
import Footer from "./footer/footerComponent";

import "./App.css";

import { ProductProvider } from "./context/productContext";

import { ErrorHandler } from "./utils/errorHandler";

export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.LogIn = () => {
            this.setState({
                ...this.state,
                loggedIn: true,
            });
            this.fetchProductData();
        };

        this.state = {
            loggedIn: false,
            products: [],
            token: "",
            LogIn: this.LogIn,
        };
    }

    fetchProductData() {
        const activeToken = localStorage.getItem("token");
        if (activeToken == null || activeToken === "") {
            this.setState({ loggedIn: false, token: null });
        } else {
            if (this.state.products.length > 0) {
                return;
            }
            axios
                .get(`${process.env.REACT_APP_BASE_API_URL}/product`, {
                    headers: { Authorization: activeToken },
                })
                .then(
                    (response) => {
                        let responseData = [];
                        if (response.data === null) {
                            ErrorHandler(
                                response,
                                "Failed to fetch data. Please try again later!"
                            );
                            return;
                        }
                        response.data.forEach((part, index) => {
                            responseData[index] = new Product({
                                name:
                                    part.item_name ||
                                    "Something went wrong while fetching this product",
                                image: part.image_url || "",
                                description: part.description || "",
                                item_id: part.item_id || "",
                                sold: part.sold || 0,
                                selling_offers: part.buying_offers || [],
                                price: part.buying_offers
                                    ? part.buying_offers[0].price
                                    : 0,
                            });
                        });
                        this.setState({
                            products: responseData,
                            loggedIn: true,
                            token: activeToken,
                        });
                    },
                    (error) => {
                        ErrorHandler(
                            error,
                            "Something went wrong! Please try again."
                        );
                        // set the token to an empty string so the landing page handler can handle it properly
                        this.setState({ loggedIn: false, token: "" });
                        localStorage.setItem("token", "");
                        localStorage.setItem("user", "");
                    }
                );
        }
    }

    componentDidMount() {
        this.fetchProductData();
    }

    render() {
        return (
            <BrowserRouter>
                <ProductProvider value={this.state}>
                    {this.state.loggedIn ? (
                        <LoggedInApp />
                    ) : (
                        <div>
                            <LandingPageHandler />
                        </div>
                    )}
                    <footer>
                        <Footer />
                    </footer>
                </ProductProvider>
            </BrowserRouter>
        );
    }
}

function LoggedInApp() {
    return (
        <div className="app-container">
            <Navbar />
            <main>
                <Switch>
                    <Route path={"/search"}>
                        <SearchComponent placeholder="Search for the product" />
                    </Route>
                    <Route path={"/products"} component={ProductPage} />
                    <Route
                        path={"/profile/notifications"}
                        component={UserNotifications}
                    />
                    <Route path={"/profile"} component={UserProfile} />
                    <Route exact path={"/"} component={LandingPageHandler} />
                </Switch>
            </main>
        </div>
    );
}

class Product {
    constructor(props) {
        this.name = props.name;
        this.image = props.image;
        this.description = props.description;
        this.item_id = props.item_id;
        this.sold = props.sold;
        this.buying_offers = props.buying_offers;
        this.price = props.price;
    }
}
