import React from "react";
import "./productWide.css";
import { Link } from "react-router-dom";

const ProductWide = (props) => {
    return (
        <div className="product-wide">
            <Link to={`/products?id=${props.product.item_id}`}>
                <div className="product-wide-information">
                    <img
                        src={props.product.image}
                        alt={props.product.name}
                        width={"70px"}
                        loading="lazy"
                    />
                    <p>
                        <strong>{props.product.name}</strong>
                        <br />
                        {props.product.description}
                    </p>
                </div>
            </Link>
        </div>
    );
};

export default ProductWide;
