import React from "react";
import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.min.css";

export function LoginHandler() {
    const options = { hideProgressBar: true, toastId: "login" };
    const notify = () => toast.success("Successfully logged in!", options);
    notify();
    return <ToastContainer autoclose={2000} />;
}

export function LoginFailed() {
    const options = { hideProgressBar: true, toastId: "login-failed" };
    const notify = () =>
        toast.error(
            "Something went wrong while logging in! Please try again.",
            options
        );
    notify();
    return <ToastContainer autoclose={2000} />;
}
